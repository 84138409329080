import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import ScrollTo from "react-scroll-into-view"
import { MEDIA_MIN_MEDIUM } from "../constants"
import { BrinkContext } from "../components/context/BrinkContext"
import Layout from "../components/Layout"
import Cookie from "../components/cookieConsent/CookieTable"
import WidgetLoader from "../components/widgets/WidgetLoader"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Button from "../components/ui/Button"
import Link from "../components/Link"

const CookiesContainer = styled.div`
  max-width: 100vh;
  padding: 0;
  margin: 1rem 2.5rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 1rem auto;
    padding: 0 1.3rem;
  }
`

const PageTitle = styled.h1`
  font-size: 2.4rem;
  line-height: 2.8rem;
  padding: 3rem 3rem 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3rem;
  }
`

const LastUpdated = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
    margin: 4rem 2rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2.5rem;

  > div {
    width: 100%;
  }

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;

    > div {
      width: auto;
    }
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 5rem;
  margin: 1rem 0;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.primary};

  ${MEDIA_MIN_MEDIUM} {
    width: auto;
    margin: 1rem 1.5rem 3rem;
  }
`

const Cookies = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 3rem;
  margin: 0 auto;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  list-style: none;
`

const CookiePolicyPage = ({
  data: { sanityCookiePolicyPage },
  pageContext
}) => {
  const { t } = useTranslation("translations")
  const { pageTitle, _updatedAt, widgets, cookies } = sanityCookiePolicyPage
  const { setCookieSettingsVisible, languageCode } = useContext(BrinkContext)

  return (
    <Layout
      meta={{ title: "Rondure - Cookie policy" }}
      pageContext={pageContext}
      invertedHeader
    >
      <Breadcrumbs>
        <Link to="/cookie-policy/">{t("Cookie policy")}</Link>
      </Breadcrumbs>
      <PageTitle>{pageTitle[languageCode] || pageTitle.en}</PageTitle>
      <LastUpdated>
        {t("Last updated at")}: {_updatedAt}
      </LastUpdated>
      <ButtonContainer>
        <ScrollTo selector=".cookiesList">
          <StyledButton hasIcon>{t("Cookies we use")}</StyledButton>
        </ScrollTo>
        <StyledButton
          hasIcon
          onClick={(e) => {
            e.preventDefault()
            setCookieSettingsVisible(true)
          }}
        >
          {t("Change my settings")}
        </StyledButton>
      </ButtonContainer>
      {widgets?.length && <WidgetLoader widgets={widgets} />}
      <CookiesContainer className="cookiesList">
        <h3>{t("Cookies we use")}:</h3>
        <Cookies>
          {cookies?.map((cookie) => (
            <Cookie key={cookie.name} cookie={cookie} />
          ))}
        </Cookies>
      </CookiesContainer>
    </Layout>
  )
}

export default CookiePolicyPage

export const query = graphql`
  query {
    sanityCookiePolicyPage {
      _updatedAt(formatString: "YYYY-MM-DD")
      pageTitle {
        en
        de
      }
      cookies {
        domain {
          en
          de
        }
        duration {
          en
          de
        }
        category {
          en
          de
        }
        name
        purpose {
          en
          de
        }
        type {
          en
          de
        }
      }
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
        ...UspWidget
      }
    }
  }
`
